import Fluxible from 'vendor/cnpm/fluxible.v0-4/index';

import NaptimeStore from 'bundles/naptimejs/stores/NaptimeStore';
import AlgoliaResultsStateStore from 'bundles/search-common/legacy-algolia/AlgoliaResultsStateStore';
import ApplicationStore from 'bundles/ssr/stores/ApplicationStore';

import component from './routes';

export type Stores = {
  ApplicationStore: ApplicationStore;
  NaptimeStore: NaptimeStore;
};

const appEnv = new Fluxible({
  component,
});

appEnv.registerStore(AlgoliaResultsStateStore);
appEnv.registerStore(ApplicationStore);
appEnv.registerStore(NaptimeStore);

export default appEnv;
