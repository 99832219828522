import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';

import 'css!../__styles__/index';

const SeoSearchApp: React.FC = ({ children }) => (
  <div className="rc-SeoSearchApp">
    <PageHeader />
    {children}
    <PageFooter />
  </div>
);

export default compose<{}, {}>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'seo_entity_page',
      page: 'search',
    },
  }))
)(SeoSearchApp);
